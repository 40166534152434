:root {
  --black: black;
  --white: white;
  --red-main: #f12c18;
  --orange-main: #ff9d29;
}

.w-layout-hflex {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

body {
  color: #1a1b1f;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 44px;
  font-weight: 400;
  line-height: 62px;
}

h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 400;
  line-height: 46px;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

p {
  margin-bottom: 10px;
}

a {
  color: #1a1b1f;
  text-decoration: underline;
  transition: opacity .2s;
  display: block;
}

a:hover {
  color: #32343a;
}

a:active {
  color: #43464d;
}

ul {
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 40px;
  list-style-type: disc;
}

li {
  margin-bottom: 10px;
}

img {
  display: block;
}

label {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

blockquote {
  border-left: 5px solid #e2e2e2;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 15px 30px;
  font-size: 20px;
  line-height: 34px;
}

figcaption {
  opacity: 1;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  line-height: 26px;
}

.divider {
  background-color: #eee;
  height: 1px;
}

.styleguide-content-wrap {
  text-align: center;
}

.section {
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.section.cc-cta {
  background-color: #f4f4f4;
  padding-left: 80px;
  padding-right: 80px;
}

.section.cc-home-wrap {
  padding-top: 0;
  padding-bottom: 0;
}

.section.cc-store-home-wrap {
  padding-top: 0;
}

.styleguide-block {
  text-align: left;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 80px;
  display: block;
}

.container {
  width: 100%;
  max-width: 1140px;
  height: auto;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.paragraph-tiny {
  font-size: 12px;
  line-height: 20px;
}

.paragraph-bigger {
  opacity: 1;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
}

.paragraph-bigger.cc-bigger-light, .paragraph-bigger.cc-bigger-white-light {
  opacity: .6;
}

.primary-button {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  border-radius: 0;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  transition: background-color .4s, opacity .4s, color .4s;
}

.primary-button:hover {
  color: #fff;
  background-color: #32343a;
}

.primary-button:active {
  background-color: #43464d;
}

.primary-button.cc-jumbo-button {
  padding: 16px 35px;
  font-size: 14px;
  line-height: 26px;
}

.primary-button.cc-contact-us {
  z-index: 5;
  color: #000;
  background-color: #ff9d29;
  border: 5px solid #000;
  border-radius: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  position: relative;
}

.primary-button.cc-white-button {
  color: #202020;
  background-color: #fff;
  padding: 16px 35px;
  font-size: 14px;
  line-height: 26px;
}

.primary-button.cc-white-button:hover {
  background-color: #fffc;
}

.primary-button.cc-white-button:active {
  background-color: #ffffffe6;
}

.label {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.label.cc-styleguide-label {
  margin-bottom: 25px;
}

.label.cc-light {
  opacity: 1;
  pointer-events: auto;
  color: #fff;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 30px;
  font-style: italic;
  font-weight: 800;
}

.label.cc-blog-date {
  opacity: .6;
  margin-top: 20px;
}

.heading-jumbo-tiny {
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
}

.rich-text {
  width: 70%;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
}

.rich-text p {
  opacity: .6;
  margin-top: 15px;
  margin-bottom: 25px;
}

.rich-text figcaption {
  opacity: .6;
}

.rich-text figure {
  margin-top: 25px;
  padding-bottom: 20px;
}

.paragraph-light {
  opacity: .6;
}

.heading-jumbo {
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 64px;
  line-height: 80px;
}

.heading-jumbo-small {
  outline-offset: 0px;
  color: #fff;
  letter-spacing: -10px;
  -webkit-text-stroke-width: 10px;
  -webkit-text-stroke-color: black;
  text-transform: none;
  border: 5px #000;
  outline: 0 #fff;
  margin-top: 25px;
  margin-bottom: 15px;
  font-family: SpotLight, Arial, sans-serif;
  font-size: 140px;
  font-weight: 300;
  line-height: 100px;
  text-decoration: none;
}

.styleguide-button-wrap {
  margin-top: 10px;
  margin-bottom: 10px;
}

.styleguide-header-wrap {
  color: #fff;
  text-align: center;
  background-color: #1a1b1f;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 460px;
  padding: 30px;
  display: flex;
}

.paragraph-small {
  font-size: 14px;
  line-height: 26px;
}

.logo-link {
  z-index: 1;
}

.logo-link:hover {
  opacity: .8;
}

.logo-link:active {
  opacity: .7;
}

.menu {
  z-index: 0;
  background-color: #fff;
  border: 5px solid #000;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: absolute;
  inset: 0%;
}

.navigation-wrap {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-family: JetBrains Mono, sans-serif;
  font-weight: 400;
  display: flex;
  position: relative;
}

.navigation {
  background-color: #0000;
  align-items: center;
  padding: 30px 50px;
  display: flex;
}

.navigation-item {
  opacity: .6;
  color: #000;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
}

.navigation-item:hover {
  opacity: .9;
}

.navigation-item:active {
  opacity: .8;
}

.navigation-item.w--current {
  opacity: 1;
  color: #1a1b1f;
  font-weight: 600;
}

.navigation-item.w--current:hover {
  opacity: .8;
  color: #32343a;
}

.navigation-item.w--current:active {
  opacity: .7;
  color: #32343a;
}

.logo-image {
  margin-left: 20px;
  display: block;
}

.navigation-items {
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer-wrap {
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
}

.webflow-link {
  opacity: .5;
  align-items: center;
  text-decoration: none;
  transition: opacity .4s;
  display: flex;
}

.webflow-link:hover {
  opacity: 1;
}

.webflow-link:active {
  opacity: .8;
}

.webflow-logo-tiny {
  margin-top: -2px;
  margin-right: 8px;
}

.cta-text {
  width: 70%;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}

.cta-wrap {
  text-align: center;
  justify-content: center;
  display: flex;
}

.intro-header {
  color: #fff;
  background-color: #f4f4f4;
  background-image: url('../img/wf-images/portfolio-2---wide.svg');
  background-position: 0 0;
  background-size: cover;
  justify-content: center;
  align-items: center;
  height: 620px;
  margin-bottom: 100px;
  display: flex;
}

.intro-header.cc-subpage {
  color: #fff;
  background-color: #000;
  background-image: none;
  height: 480px;
}

.intro-content {
  text-align: center;
  width: 70%;
  max-width: 1140px;
}

.intro-content.cc-homepage {
  margin-bottom: 20px;
}

.motto-wrap {
  text-align: center;
  width: 80%;
  margin-bottom: 85px;
  margin-left: auto;
  margin-right: auto;
}

.about-story-wrap {
  text-align: center;
  width: 80%;
  margin: 80px auto;
}

.our-services-grid {
  grid-column-gap: 80px;
  grid-row-gap: 60px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". . ."
                       ". . .";
  margin-bottom: 120px;
}

.section-heading-wrap {
  text-align: center;
  margin-bottom: 80px;
}

.service-icon {
  margin-bottom: 30px;
}

.status-message {
  color: #fff;
  text-align: center;
  background-color: #202020;
  padding: 9px 30px;
  font-size: 14px;
  line-height: 26px;
}

.status-message.cc-success-message {
  background-color: #12b878;
}

.status-message.cc-error-message {
  background-color: #db4b68;
}

.status-message.cc-no-data {
  width: 70%;
  margin: 100px auto;
  display: block;
}

.contact-form-wrap {
  border: 1px solid #eee;
  padding: 45px 50px 50px;
}

.contact-form-grid {
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template: ". ."
  / 2.5fr 1fr;
  align-items: start;
}

.details-wrap {
  margin-bottom: 30px;
}

.get-in-touch-form {
  flex-direction: column;
  display: flex;
}

.text-field {
  border: 1px solid #e4e4e4;
  border-radius: 0;
  margin-bottom: 18px;
  padding: 21px 20px;
  font-size: 14px;
  line-height: 26px;
  transition: border-color .4s;
}

.text-field:hover {
  border-color: #e3e6eb;
}

.text-field:active, .text-field:focus {
  border-color: #43464d;
}

.text-field::placeholder {
  color: #32343a66;
}

.text-field.cc-contact-field {
  margin-bottom: 25px;
}

.text-field.cc-textarea {
  height: 200px;
  padding-top: 12px;
}

.contact-form {
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.contact-form-heading-wrap {
  margin-bottom: 40px;
}

.contact-heading {
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 400;
}

.map {
  height: 460px;
  line-height: 20px;
}

.contact-team-name-wrap {
  margin-bottom: 30px;
}

.our-contacts {
  grid-column-gap: 80px;
  grid-row-gap: 60px;
  text-align: center;
  grid-template: ". . ."
  / 1fr 1fr 1fr;
}

.contact-team-details-wrap {
  margin-top: 30px;
}

.contact-team-pic {
  background-color: #f4f4f4;
  height: 150px;
  margin-bottom: 30px;
}

.team-pic {
  background-color: #f4f4f4;
  width: 100%;
  height: 420px;
  margin-bottom: 40px;
}

.team-members {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". . ."
                       ". . .";
}

.team-member-title-wrap {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
  display: flex;
}

.team-member-name {
  opacity: 1;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
}

.projects-grid {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template: "."
                 "."
                 "."
                 / 1fr;
}

.project-name-wrap {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
  display: flex;
}

.project-name-link {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  text-decoration: none;
}

.project-name-link:hover {
  opacity: .8;
}

.project-name-link:active {
  opacity: .7;
}

.project-cover-link {
  margin-bottom: 40px;
}

.project-cover-link:hover {
  opacity: .8;
}

.project-cover-link:active {
  opacity: .7;
}

.project-overview-header {
  color: #fff;
  background-color: #f4f4f4;
  background-image: url('../img/wf-images/portfolio-1---wide.svg');
  background-position: 50%;
  background-size: cover;
  justify-content: center;
  align-items: center;
  height: 620px;
  display: flex;
}

.project-overview-header.cc-project-2-header {
  background-image: url('../img/wf-images/portfolio-2---wide.svg');
  background-position: 50%;
}

.project-overview-header.cc-project-3-header {
  background-image: url('../img/wf-images/portfolio-3---wide.svg');
  background-size: cover;
}

.project-details-grid {
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template: ". ."
  / 1fr 2fr;
  align-items: start;
}

.detail-image {
  width: 100%;
  margin-bottom: 30px;
}

.blog-detail-header-wrap {
  width: 70%;
  margin: 60px auto;
}

.detail-header-image {
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: cover;
  width: 100%;
  height: 620px;
  margin-bottom: 60px;
  display: block;
}

.detail-header-image.w--current {
  margin-bottom: 60px;
}

.blog-list-wrap {
  margin-bottom: 100px;
}

.blog-item {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.blog-preview-image {
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 0 0;
  background-size: cover;
  width: 100%;
  height: 620px;
  margin-bottom: 45px;
  transition: opacity .6s;
  display: block;
}

.blog-preview-image:hover {
  opacity: .8;
}

.blog-preview-image:active {
  opacity: .7;
}

.blog-summary-wrap {
  text-align: left;
  width: 70%;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.blog-heading-link {
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 400;
  line-height: 46px;
  text-decoration: none;
}

.blog-heading-link:hover {
  opacity: .8;
}

.blog-heading-link:active {
  opacity: .7;
}

.contact-email-link {
  opacity: .6;
  margin-bottom: 5px;
}

.contact-email-link:hover {
  opacity: 1;
}

.contact-email-link:active {
  opacity: .8;
}

.protected-form {
  flex-direction: column;
  display: flex;
}

.protected-wrap {
  justify-content: center;
  padding-top: 90px;
  padding-bottom: 100px;
  display: flex;
}

.protected-heading {
  margin-bottom: 30px;
}

.utility-page-wrap {
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  padding: 30px;
  display: flex;
}

._404-wrap {
  background-color: #1a1b1f;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
}

._404-content-wrap {
  margin-bottom: 20px;
}

.home-content-wrap {
  margin-top: 80px;
}

.home-section-wrap {
  margin-bottom: 30px;
}

.section-heading {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 400;
}

.about-grid {
  grid-column-gap: 80px;
  grid-row-gap: 30px;
  grid-template: ". ."
  / 1fr 2fr;
  align-items: center;
  margin-bottom: 80px;
}

.about-grid.cc-about-2 {
  grid-template-columns: 2fr 1fr;
  grid-template-areas: ". .";
}

.intro-text {
  width: 80%;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
}

.collection-wrap {
  flex-wrap: wrap;
  place-content: flex-start center;
  align-items: flex-start;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.work-heading {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 400;
}

.blog-heading {
  text-align: center;
  margin-bottom: 60px;
}

.blog-preview-wrap {
  width: 33.33%;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 45px;
}

.business-article-heading {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  text-decoration: none;
}

.business-article-heading:hover {
  opacity: .8;
}

.business-article-heading:active {
  opacity: .7;
}

.secondary-button {
  color: #000;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #fff;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
}

.secondary-button.cc-jumbo-button {
  padding: 16px 35px;
  font-size: 14px;
  line-height: 26px;
}

.body {
  text-align: center;
  background-color: #f12c18;
  display: block;
}

.text-block {
  font-weight: 800;
}

.text-span {
  color: #ff9d29;
}

.image {
  position: absolute;
  inset: 23% auto 0% 0%;
}

.image-2 {
  width: 150px;
  padding-top: 0;
  position: absolute;
  inset: 58% auto 0% 0%;
}

.image-3 {
  position: absolute;
  inset: 88% auto 0% 0%;
}

.image-4 {
  position: absolute;
  inset: 60% 26% 0% 20%;
}

.image-5 {
  position: absolute;
  inset: 62% 0% 0% 49%;
}

.image-6 {
  position: absolute;
  inset: 86% 0% 0% 27%;
}

.image-7 {
  position: absolute;
  inset: 68% 0% 0% 74%;
}

.image-8 {
  position: absolute;
  inset: 30% 0% 34% auto;
}

.image-9 {
  text-align: center;
  object-fit: contain;
  flex: 0 auto;
  display: block;
}

.container-2 {
  text-align: center;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  display: flex;
}

.text-block-2 {
  color: #000;
  white-space: nowrap;
  word-break: keep-all;
  background-color: #fff;
  border: 7px solid #000;
  flex-flow: row;
  justify-content: center;
  align-self: auto;
  align-items: center;
  width: 200vw;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Nunito, sans-serif;
  font-size: 30px;
  font-style: italic;
  font-weight: 800;
  display: block;
  position: absolute;
  overflow: visible;
}

.section-2 {
  width: 200vw;
  display: block;
  position: absolute;
  inset: 98% 0% 0% -14%;
  transform: rotate(-12.59deg);
}

.section-3 {
  margin-top: 34px;
}

.div-block {
  margin-top: 85px;
}

.section-4 {
  height: 120vh;
}

.heading {
  color: #fff;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 800;
  line-height: 70px;
  text-decoration-style: solid;
}

.text-span-2 {
  background-color: #000;
  border-radius: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.grid {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  place-content: center stretch;
}

.section-5 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  text-align: left;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin-left: 0;
  padding-top: 100px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
}

.container-3 {
  text-align: center;
  background-color: #fff0;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
  padding: 0 0% 20px;
  display: flex;
}

.image-10 {
  text-align: center;
  height: 270px;
  margin-top: -100px;
  padding-top: 0;
  padding-left: 20px;
  padding-right: 20px;
  overflow: clip;
}

.container-4 {
  background-color: #fff;
  border: 5px solid #000;
  border-radius: 30px;
  height: auto;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.container-4--jp {
  min-height: 470px;
  max-width: 350px;
}

.heading-2 {
  font-family: Nunito, sans-serif;
  font-size: 28px;
  font-weight: 800;
  line-height: 1.1em;
}

.paragraph {
  color: var(--black);
  text-align: left;
  font-family: JetBrains Mono, sans-serif;
  line-height: 1.4em;
}

.image-11 {
  border: 5px solid #000;
  border-radius: 20px;
  flex: 1;
}

.image-12 {
  flex: 0 auto;
  width: auto;
  max-width: none;
  height: 300px;
}

.section-6 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: center;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: 1200px;
  min-width: 0;
  max-width: 1200px;
  padding: 0 40px;
  display: flex;
}

.container-5 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  text-align: left;
  -webkit-text-fill-color: inherit;
  background-color: #0000;
  background-image: url('../img/wf-images/Rectangle-1268.svg'), url('../img/wf-images/plato.svg');
  background-position: 50% 100%, 50% 0;
  background-repeat: no-repeat, no-repeat;
  background-size: contain, contain;
  background-clip: border-box;
  border-radius: 30px;
  max-width: 1200px;
  padding: 100px 20px 40px;
  display: flex;
}

.section-7 {
  padding: 120px 40px;
}

.container-6 {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  text-align: left;
  flex-flow: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  min-height: 100px;
  max-height: none;
  font-family: JetBrains Mono, sans-serif;
  display: flex;
}

.button {
  border: 2px solid var(--white);
  background-color: #3898ec00;
  border-radius: 40px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.button:hover {
  color: #fff;
  background-color: #32343a;
}

.container-7 {
  -webkit-text-fill-color: inherit;
  background-clip: border-box;
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
}

.paragraph-2 {
  color: #fff;
}

.grid-2 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.container-8 {
  outline-offset: 0px;
  text-align: justify;
  background-color: #fff;
  border: 5px solid #000;
  border-radius: 30px;
  outline: 3px #555;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  padding: 30px;
  display: block;
}

.heading-3 {
  font-family: Nunito, sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.3em;
}

.container-9 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  text-align: center;
  flex-flow: row;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.image-13 {
  width: 30px;
  min-width: 0;
}

.section-8 {
  padding-left: 50px;
  padding-right: 50px;
}

.paragraph-3 {
  text-align: left;
  flex: 1;
  height: auto;
  min-height: auto;
  max-height: none;
  margin-bottom: 0;
  font-family: JetBrains Mono, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
}

.paragraph-4 {
  text-align: left;
}

.container-10 {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  background-color: var(--white);
  border: 5px solid #000;
  border-radius: 30px;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 0;
  display: flex;
}

.section-9 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
  display: block;
}

.image-14 {
  padding-bottom: 100px;
}

.heading-4 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 1em;
}

.div-block-2 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 0;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding-top: 0;
  text-decoration: none;
  display: flex;
}

.grid-3 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.section-10 {
  margin-top: 120px;
  margin-bottom: 120px;
  padding-left: 40px;
  padding-right: 40px;
}

.section-11 {
  text-align: left;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-11--jp {
  padding: 20px 20px 20px 20px;
  flex-direction: column;
}

.jp-title {
  font-size: 100px;
  line-height: 100px;
  color: white;
  font-weight: 900;
  letter-spacing: -5px;
  text-shadow: 0 0 10px black;
  -webkit-text-stroke: 4px black;
}

.jp-sub-title {
  font-size: 24px;
  line-height: 26px;
  color: white;
  text-shadow: 0 0 2px black;
}

.jp-section-title {
  font-weight: 900;
  font-size: 70px;
  line-height: 70px;
  color: white;
  font-style: italic;
  letter-spacing: -5px;
  max-width: 700px;
  margin: 0 auto;
}

.jp-fat-amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto;
  max-width: 570px;
  padding: 20px 30px;
  border: 5px solid white;
  border-radius: 50px;
}

.jp-fat-amount__text {
  font-size: 70px;
  line-height: 70px;
  color: white;
  font-weight: 900;
  font-style: italic;
  margin-bottom: 0;
}

.jp-footer {
  padding-top: 80px;
}

.section-12 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-image: url('../img/wf-images/Mask-group.svg');
  background-position: 50% 0;
  background-size: cover;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  padding-top: 180px;
  padding-bottom: 40%;
  display: flex;
}

.section-12--jp {
  background-image: url('../img/jp-bg.png');
  padding-bottom: 46%;
}

.section-12-content {
  padding: 0 20px;
  text-align: center;
  flex: 1;
  max-width: 900px;
}

.section-13--jp {
  padding-bottom: 0;
}

.t-container {
  padding: 0 20px;
}

.flex-block {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.image-15 {
  width: 50%;
}

.image-16, .image-17 {
  width: 200px;
}

.section-13 {
  max-width: 1200px;
  margin: 0 auto;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 30px;
  display: flex;
}

.section-14 {
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  margin-bottom: 120px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
}

.container-10-copy {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  background-color: var(--white);
  border: 5px solid #000;
  border-radius: 30px;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin-bottom: 20px;
  margin-right: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 0;
  display: flex;
}

.section-15 {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-16 {
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.container-10-copy {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  background-color: var(--white);
  border: 5px solid #000;
  border-radius: 30px;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 0;
  display: flex;
}

.grid-3-copy {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-content: stretch;
  align-self: center;
}

.grid-3-copy.newgrid {
  grid-template-columns: 1fr 1fr 1fr;
}

.container-8-copy {
  outline-offset: 0px;
  text-align: justify;
  background-color: #fff;
  border: 5px solid #000;
  border-radius: 30px;
  outline: 3px #555;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
  padding: 30px 20px;
  display: block;
}

.navbar-no-shadow {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  display: flex;
}

.navbar-no-shadow-container {
  z-index: 5;
  background-color: #0000;
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.container-regular {
  width: 100%;
  max-width: 1260px;
  min-height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.navbar-wrapper {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nav-menu {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
}

.nav-link {
  color: #1a1b1f;
  letter-spacing: .25px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

.nav-link:hover {
  color: #1a1b1fbf;
}

.nav-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 4px;
  outline: 2px solid #0050bd;
}

.nav-dropdown {
  margin-left: 5px;
  margin-right: 5px;
}

.nav-dropdown-toggle {
  letter-spacing: .25px;
  padding: 5px 30px 5px 10px;
  font-size: 14px;
  line-height: 20px;
}

.nav-dropdown-toggle:hover {
  color: #1a1b1fbf;
}

.nav-dropdown-toggle:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-toggle[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-icon {
  margin-right: 10px;
}

.nav-dropdown-list {
  background-color: #fff;
  border-radius: 12px;
}

.nav-dropdown-list.w--open {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-dropdown-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.nav-dropdown-link:focus-visible {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-dropdown-link[data-wf-focus-visible] {
  outline-offset: 0px;
  color: #0050bd;
  border-radius: 5px;
  outline: 2px solid #0050bd;
}

.nav-button-wrapper {
  margin-left: 120px;
}

.button-primary {
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background-color: #1a1b1f;
  padding: 12px 25px;
  font-size: 12px;
  line-height: 20px;
  transition: all .2s;
}

.button-primary:hover {
  color: #fff;
  background-color: #32343a;
}

.button-primary:active {
  background-color: #43464d;
}

.section-6-copy {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: center;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  min-width: 0;
  max-width: none;
  padding: 0 40px;
  display: flex;
}

.section-6-1 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: center;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: 900px;
  min-width: 0;
  max-width: 1200px;
  padding: 0 40px 30px;
  display: flex;
}

.section-5-copy {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  text-align: left;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin-left: 0;
  padding-top: 100px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
}

.section-6-copy {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: center;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: 1200px;
  min-width: 0;
  max-width: 1200px;
  padding: 0;
  display: flex;
}

.section-6-copy-copy {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: center;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  min-width: 0;
  max-width: 900px;
  padding: 0;
  display: flex;
}

.paragraph-5 {
  color: #fff;
  font-family: JetBrains Mono, sans-serif;
}

.image-21 {
  width: 250px;
}

.container-11 {
  background-color: #fff;
  border: 5px solid #000;
  border-radius: 30px;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.heading-5 {
  text-align: left;
  flex: 1;
  font-family: Nunito, sans-serif;
  font-size: 30px;
  font-weight: 800;
}

.grid-4 {
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  align-self: center;
  place-items: center;
}

.section-17 {
  padding-bottom: 70px;
}

.div-block-3 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.container-8-copy-copy {
  outline-offset: 0px;
  text-align: justify;
  background-color: #fff;
  border: 5px solid #000;
  border-radius: 30px;
  outline: 3px #555;
  justify-content: flex-start;
  align-items: center;
  width: 22%;
  height: 100%;
  margin-bottom: 0;
  margin-left: 0;
  padding: 30px 20px;
  display: block;
}

.section-18 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: center;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.grid-3-copy-copy {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  place-content: stretch;
  align-self: center;
  padding-top: 80px;
  padding-left: 12.5%;
  padding-right: 12.5%;
}

.grid-3-copy-copy.newgrid {
  grid-template-columns: 1fr 1fr 1fr;
}

.testimonials-content {
  max-width: 1000px;
  margin-top: 40px;
  padding: 20px;
  overflow-x: hidden;
}

@media screen and (max-width: 991px) {
  .section.cc-cta {
    padding-left: 0;
    padding-right: 0;
  }

  .styleguide-block {
    text-align: center;
  }

  .primary-button {
    justify-content: center;
  }

  .primary-button.cc-contact-us {
    display: none;
  }

  .heading-jumbo {
    font-size: 56px;
    line-height: 70px;
  }

  .logo-link.w--current {
    text-align: left;
    flex: 1;
  }

  .menu-icon {
    display: block;
  }

  .menu {
    border-width: 0;
    border-radius: 0;
    margin-left: 30px;
    position: static;
  }

  .navigation-wrap {
    background-color: #fff;
    border: 5px solid #000;
    border-radius: 100px;
  }

  .navigation {
    padding: 25px 30px;
  }

  .navigation-item {
    text-align: center;
    padding: 15px 30px;
    transition: background-color .4s, opacity .4s, color .4s;
  }

  .navigation-item:hover {
    background-color: #f7f8f9;
  }

  .navigation-item:active {
    background-color: #eef0f3;
  }

  .menu-button {
    padding: 0;
  }

  .menu-button.w--open {
    background-color: #0000;
  }

  .navigation-items {
    background-color: #fff;
  }

  .cta-text {
    width: auto;
  }

  .cta-wrap {
    width: auto;
    padding: 80px 50px 90px;
  }

  .our-services-grid {
    text-align: center;
    grid-template: ". ."
                   ". ."
                   ". ."
                   / 1fr 1fr;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .service-icon {
    display: inline-block;
  }

  .contact-form-grid {
    grid-row-gap: 50px;
    text-align: center;
    grid-template: "."
                   "."
                   / 1fr;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .get-in-touch-form {
    text-align: left;
  }

  .our-contacts {
    grid-template: "."
                   "."
                   "."
                   / 1fr;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .team-members {
    grid-template: ". ."
                   ". ."
                   ". ."
                   / 1fr 1fr;
  }

  .team-member-title-wrap {
    margin-bottom: 30px;
  }

  .project-details-grid {
    grid-row-gap: 50px;
    text-align: center;
    grid-template: "."
                   "."
                   / 1fr;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .blog-detail-header-wrap {
    width: 70%;
  }

  .detail-header-image {
    height: 460px;
  }

  .blog-item {
    width: 80%;
  }

  .blog-preview-image {
    height: 460px;
  }

  .blog-summary-wrap {
    width: 100%;
  }

  .about-grid {
    grid-row-gap: 50px;
    text-align: center;
    grid-template: "."
                   "."
                   / 1fr;
  }

  .about-grid.cc-about-2 {
    grid-template-columns: 1fr;
    grid-template-areas: "."
                         ".";
  }

  .intro-text {
    width: auto;
  }

  .collection-wrap {
    flex-flow: column;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }

  .blog-preview-wrap {
    text-align: center;
    width: 80%;
    padding-right: 15px;
  }

  .paragraph {
    font-size: 14px;
  }

  .image-12 {
    width: 100%;
    height: auto;
  }

  .section-6 {
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
  }

  .container-7 {
    padding-left: 0;
    padding-right: 0;
  }

  .grid-2 {
    grid-template-columns: 1fr 1fr;
  }

  .container-8 {
    margin-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-8 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .grid-3 {
    grid-template-columns: 1fr 1fr;
  }

  .section-10 {
    margin-bottom: 0;
  }

  .section-11 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .section-12 {
    background-image: url('../img/wf-images/Mask-group-TABLET.svg');
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 100%;
  }

  .section-12--jp {
    background-image: url('../img/jp-bg.png');
    padding-bottom: 65%;
  }

  .image-15 {
    width: 60%;
  }

  .image-16, .image-17 {
    width: 150px;
  }

  .section-14 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .grid-3-copy {
    grid-template-columns: 1fr 1fr;
  }

  .container-8-copy {
    height: auto;
    margin-bottom: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .nav-menu-wrapper {
    background-color: #0000;
  }

  .nav-menu {
    background-color: #fff;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .nav-link {
    padding-left: 5px;
    padding-right: 5px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .nav-button-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .menu-button-2 {
    padding: 12px;
  }

  .menu-button-2.w--open {
    color: #fff;
    background-color: #a6b1bf;
  }

  .section-6-1 {
    width: 100%;
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
  }

  .section-5-copy {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .section-6-copy, .section-6-copy-copy {
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
  }

  .heading-5 {
    flex: 0 auto;
    font-size: 24px;
  }

  .section-17 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .container-8-copy-copy {
    margin-bottom: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .grid-3-copy-copy {
    grid-template-columns: 1fr 1fr;
    padding-top: 0;
    padding-left: 0%;
    padding-right: 0%;
  }
}

@media screen and (max-width: 767px) {
  .jp-title {
    font-size: 46px;
    line-height: 46px;
    text-shadow: 0 0 4px black;
    -webkit-text-stroke: 2px black;
  }

  .jp-fat-amount {
    padding: 10px 15px;
  }

  .jp-fat-amount__text {
    font-size: 38px;
    line-height: 38px;
  }

  .jp-section-title {
    font-weight: 900;
    font-size: 38px;
    line-height: 38px;
  }

  .section {
    margin-left: 15px;
    margin-right: 15px;
  }

  .section.cc-cta {
    padding: 15px;
  }

  .section.cc-home-wrap, .section.cc-store-home-wrap {
    margin-left: 15px;
    margin-right: 15px;
  }

  .container {
    text-align: center;
  }

  .paragraph-bigger {
    font-size: 16px;
    line-height: 28px;
  }

  .rich-text {
    text-align: left;
    width: 90%;
    max-width: 470px;
  }

  .heading-jumbo {
    font-size: 50px;
    line-height: 64px;
  }

  .heading-jumbo-small {
    font-size: 30px;
    line-height: 52px;
  }

  .logo-link {
    padding-left: 0;
  }

  .navigation {
    padding: 20px 30px;
  }

  .cta-wrap {
    padding-left: 30px;
    padding-right: 30px;
  }

  .intro-content {
    width: 80%;
  }

  .our-services-grid {
    grid-row-gap: 60px;
    grid-template: "."
                   "."
                   "."
                   "."
                   "."
                   "."
                   / 1fr;
  }

  .status-message.cc-no-data {
    width: 80%;
    max-width: 470px;
  }

  .contact-form-wrap {
    padding: 30px;
  }

  .text-field.cc-contact-field, .text-field.cc-textarea {
    text-align: left;
  }

  .team-pic {
    height: 300px;
    margin-bottom: 30px;
  }

  .team-member-name {
    font-size: 16px;
    line-height: 28px;
  }

  .project-name-wrap {
    margin-bottom: 30px;
  }

  .project-name-link {
    font-size: 16px;
    line-height: 28px;
  }

  .detail-image {
    margin-bottom: 15px;
  }

  .blog-detail-header-wrap {
    text-align: left;
    width: 90%;
    max-width: 470px;
  }

  .blog-item {
    width: 90%;
    max-width: 470px;
  }

  .blog-summary-wrap {
    text-align: center;
  }

  .utility-page-wrap {
    padding: 15px;
  }

  ._404-wrap {
    padding: 30px;
  }

  .heading {
    font-size: 40px;
    line-height: 1.4em;
  }

  .section-5 {
    grid-column-gap: 120px;
    grid-row-gap: 120px;
    flex-flow: column;
  }

  .image-12 {
    width: 60%;
  }

  .section-6 {
    padding-left: 0;
    padding-right: 0;
  }

  .container-5 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-flow: column;
    padding-left: 0;
    padding-right: 0;
  }

  .section-7 {
    background-color: #0000;
    padding-left: 40px;
    padding-right: 40px;
  }

  .container-6 {
    background-color: var(--black);
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
  }

  .paragraph-2 {
    text-align: center;
  }

  .grid-2 {
    grid-template-columns: 1fr;
  }

  .container-8 {
    margin-bottom: 0;
  }

  .image-14 {
    width: 80%;
    min-width: auto;
  }

  .section-10 {
    margin-top: 100px;
  }

  .image-15 {
    width: 80%;
    height: auto;
    max-height: none;
    overflow: clip;
  }

  .image-18 {
    width: 70%;
  }

  .section-14 {
    margin-top: 100px;
  }

  .container-8-copy {
    margin-bottom: 70px;
  }

  .navbar-brand {
    padding-left: 0;
  }

  .nav-menu {
    flex-direction: column;
    padding-bottom: 30px;
    padding-left: 0;
  }

  .nav-link {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
  }

  .nav-dropdown {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px #0000000d;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .section-6-copy, .section-6-1 {
    padding-left: 0;
    padding-right: 0;
  }

  .section-5-copy {
    grid-column-gap: 120px;
    grid-row-gap: 120px;
    flex-flow: column;
  }

  .section-6-copy, .section-6-copy-copy {
    padding-left: 0;
    padding-right: 0;
  }

  .container-8-copy-copy {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 36px;
    line-height: 52px;
  }

  .label.cc-light {
    font-size: 24px;
  }

  .rich-text {
    width: 100%;
    max-width: none;
  }

  .heading-jumbo {
    font-size: 36px;
    line-height: 48px;
  }

  .logo-link.w--current {
    flex-flow: row;
    flex: 1;
    order: 0;
    justify-content: flex-start;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .menu {
    margin-left: 15px;
    padding-right: 10px;
  }

  .navigation {
    padding-left: 10px;
    padding-right: 10px;
  }

  .menu-button, .menu-button.w--open {
    flex: none;
  }

  .logo-image {
    text-align: left;
    margin-left: 10px;
  }

  .cta-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }

  .status-message.cc-no-data, .contact-form-grid {
    width: 100%;
  }

  .our-contacts {
    width: 90%;
  }

  .team-members {
    grid-template: "."
                   "."
                   "."
                   "."
                   "."
                   "."
                   / 1fr;
  }

  .project-details-grid {
    width: 100%;
  }

  .blog-detail-header-wrap, .blog-item {
    width: 100%;
    max-width: none;
  }

  .heading {
    font-size: 34px;
    line-height: 1.2em;
  }

  .section-5 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .container-3 {
    padding-bottom: 0;
  }

  .heading-2 {
    font-size: 24px;
  }

  .image-11, .image-12 {
    width: 80%;
  }

  .section-6 {
    padding: 0 0 30px;
  }

  .container-5 {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .section-7 {
    padding: 50px 20px;
  }

  .container-6 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .paragraph-2 {
    line-height: 1.4em;
  }

  .grid-2 {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }

  .container-8 {
    margin-bottom: 0;
  }

  .section-8 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .container-10 {
    flex-flow: column-reverse;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-9 {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }

  .div-block-2 {
    padding-top: 20px;
  }

  .grid-3 {
    grid-template-columns: 1fr;
  }

  .section-10 {
    margin-top: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-11 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-12 {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    background-image: url('../img/wf-images/BACKGROUND-PHONE.svg');
  }

  .section-12--jp {
    background-image: url('../img/jp-bg.png');
  }

  .testimonials-content {
    padding: 10px;
  }

  .flex-block {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }

  .image-15 {
    width: 100%;
  }

  .image-16, .image-17 {
    width: 120px;
  }

  .italic-text, .italic-text-2 {
    font-size: 28px;
  }

  .italic-text-3 {
    font-size: 30px;
  }

  .image-18 {
    width: 100%;
  }

  .section-13 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section-13--jp {
    padding-bottom: 0;
  }

  .section-14 {
    margin-top: 70px;
    margin-bottom: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .container-10-copy {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .grid-3-copy {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  .container-8-copy {
    margin-bottom: 70px;
  }

  .image-19 {
    width: 50%;
  }

  .image-20 {
    text-align: left;
    flex: 0 auto;
  }

  .nav-menu {
    flex-direction: column;
  }

  .nav-button-wrapper {
    width: auto;
  }

  .section-6-1 {
    padding: 0 20px 30px;
  }

  .section-5-copy {
    padding-left: 0;
    padding-right: 0;
  }

  .section-6-copy, .section-6-copy-copy {
    padding: 0;
  }

  .paragraph-5 {
    font-size: 14px;
  }

  .image-21 {
    width: 200px;
  }

  .container-11 {
    flex-flow: column;
    padding-bottom: 20px;
  }

  .heading-5 {
    min-height: auto;
    line-height: 1.4em;
  }

  .grid-4 {
    grid-template-columns: 1fr 1fr 1fr;
    align-self: center;
  }

  .section-17 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .container-8-copy-copy {
    margin-bottom: 70px;
  }

  .grid-3-copy-copy {
    grid-template-columns: 1fr;
  }
}

#w-node-_78f60026-295f-c246-4ff0-808dc28534eb-76cf9644, #w-node-a2d50461-56b2-6c05-b849-b22cb670f678-76cf9644, #w-node-ede2ff69-9d2d-ba2c-df88-a48420d42c43-76cf9644, #w-node-_863c3c3b-cc90-31d7-d613-d8535a977f97-76cf9644, #w-node-dc7116e3-9c85-88bb-2b8e-78ad8177c9a5-76cf9644, #w-node-_43ddc837-5ba0-2181-8e43-bcf06a307c72-76cf9644 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  place-self: stretch stretch;
}

#w-node-c693eff1-f845-f143-58f0-5aa1d32fc5e4-76cf9644 {
  justify-self: stretch;
}

#w-node-_37478dce-7e20-d323-9123-f06c9f5f4057-76cf9644, #w-node-_7b9a085e-061f-56f7-5054-684ffebcd40b-76cf9644, #w-node-_0a1efb26-516b-41bc-251e-d943b221d65e-76cf9644 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  place-self: stretch stretch;
}

#w-node-_36ad9f2e-5b99-4a21-b0dc-e294ff152f20-76cf9644 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  place-self: center;
}

#w-node-_2ab7a458-45e8-b42e-dfd2-0530ea483a18-76cf9644, #w-node-_2ab7a458-45e8-b42e-dfd2-0530ea483a22-76cf9644, #w-node-_2ab7a458-45e8-b42e-dfd2-0530ea483a2c-76cf9644 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  place-self: stretch stretch;
}


@font-face {
  font-family: 'SpotLight';
  src: url('../fonts/Spot-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
